$(document).on("turbolinks:load", function () {
  initializeStripe();
});

window.initializeStripe = () => {
  const stripeSubmitElement = $("#stripe-submit");
  const stripeReaderSubmit = $("#stripe-reader-submit")
  const order_id = $(".js-order-id").data("order-id");
  const balanceDue = $(".js-stripe-amount").val();
  const page = $(".js-payment-page").val() || window.location.href.includes("tab=order-profile") ? 'Order Profile' : ''
  const $preloader = $("#preloader");
  const $stripeErrors = $("#stripe-charges-errors-form");
  const stripe = Stripe(__STRIPE_PUBLISHABLE_KEY__);
  var loggedInUserId = null;
  let messageTimeout;

  const stopLoader = () => {
    sessionStorage.removeItem('showLoader');
    $preloader.addClass('hidden');
  }
  stopLoader();
  let redirectUrl = $(".js-payment-page-url").val();

  var discoveredReaders;
  var paymentIntentId;
  var terminal = StripeTerminal.create({
    onFetchConnectionToken: fetchConnectionToken,
    onUnexpectedReaderDisconnect: unexpectedDisconnect,
  });
  console.log('stripe',stripe);
  var config = {simulated: false };
  terminal.discoverReaders(config).then(function(discoverResult) {
    if (discoverResult.error) {
      console.log('Failed to discover: ', discoverResult.error);
    } else if (discoverResult.discoveredReaders.length === 0) {
        console.log('No available readers.');
    } else {
        discoveredReaders = discoverResult.discoveredReaders;
        if (discoveredReaders != undefined){
          var selectedReader = discoveredReaders[0];
          terminal.connectReader(selectedReader).then(function(connectResult) {
            if (connectResult.error) {
              console.log('Failed to connect: ', connectResult.error);
            } else {
                console.log('Connected to reader: ', connectResult.reader.label);
            }
          });
        }
    }
  });

  async function fetchPaymentIntentClientSecret(amount) {
    const bodyContent = JSON.stringify({ amount: amount, payment_method: "Stripe Reader" });
    return await fetch(`/service_orders/${order_id}/create_payment_intent`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: bodyContent
    })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return data;
    });
  }

   function collectPayment(amount) {
    fetchPaymentIntentClientSecret(amount).then(function(data) {
      const {clientSecret, chargeId, postalCode} = data;
      //terminal.setSimulatorConfiguration({testCardNumber: '4242424242424242'});
      terminal.collectPaymentMethod(clientSecret).then(function(result) {
        if (result.error) {
          // Placeholder for handling result.error
        } else {
            terminal.processPayment(result.paymentIntent).then(function(result) {
            if (result.error) {
              stopLoader();
              console.log(result.error)
            } else if (result.paymentIntent) {
                paymentIntentId = result.paymentIntent.id;
                capture(paymentIntentId, chargeId, postalCode);
                stopLoader();
                setTimeout(function() {
                  window.location.reload();
              }, 2000);
            }
          });
        }
      });
    });
  }


 async function capture(paymentIntentId, chargeId, postalCode) {
  return await fetch('/service_orders/capture_payment_intent', {
    method: "POST",
    headers: {
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({"payment_intent_id": paymentIntentId, charge_id: chargeId, postal_code: postalCode, page: page})
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(data) {
    log('server.capture', data);
   });
  }

  function unexpectedDisconnect() {
    // In this function, your app should notify the user that the reader disconnected.
    // You can also include a way to attempt to reconnect to a reader.
    console.log("Disconnected from reader")
  }

  function fetchConnectionToken() {
    const order_id = $(".js-order-id").data("order-id")
    if (order_id) {
     // Do not cache or hardcode the ConnectionToken. The SDK manages the ConnectionToken's lifecycle.
      return fetch(`/service_orders/${order_id}/connection_token`, { method: "POST" })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data.secret;
      });
    }
  }

  if ((balanceDue !== undefined && balanceDue > 0) &&
      (page === "Order Profile" || page === "New Order" || page === "Customer Portal")) {

    let elements;

    if (page !== "Customer Portal") {
      $(".js-stripe-amount").on('change keyup', function () {
        initialize();
      });
    }

    initialize();

    if (sessionStorage.getItem('showLoader')) {
      $preloader.removeClass('hidden');
      sessionStorage.removeItem('showLoader');
    }

    stripeSubmitElement.on("click", function () {
      $preloader.removeClass('hidden');
      sessionStorage.setItem("showLoader", 'true');
      handleSubmit();
    });

    stripeReaderSubmit.on("click", function () {
      $preloader.removeClass('hidden');
      sessionStorage.setItem("showLoader", 'true');
      handleSubmit();
    });

    async function initialize() {
      const customerPortalPaymentMethod = $(".js-stripe-payment-method").val();
      const amount = parseFloat($(".js-stripe-amount").val());
      if (amount != parseFloat($(".js-stripe-amount").attr('data-value')) && (amount < parseFloat($(".js-stripe-amount").attr('data-min')) || amount > parseFloat($(".js-stripe-amount").attr('data-max'))))

      {

        alert(`valid values are between ${parseFloat($(".js-stripe-amount").attr('data-min'))} - ${parseFloat($(".js-stripe-amount").attr('data-max'))} and ${parseFloat($(".js-stripe-amount").attr('data-value'))}`);
        $(".js-stripe-amount").val(parseFloat($(".js-stripe-amount").attr('data-value')))
        return;
      }
      let payment_method;

      if ($("#charge_payment_method_credit_card").is(":checked") ||
        $("#order_charge_payment_method_credit_card").is(":checked") ||
        customerPortalPaymentMethod === 'card') {
        payment_method = 'Credit Card';
      }
      if ($("#charge_payment_method_stripe_reader").is(":checked") == false ||
        $("#order_charge_payment_method_stripe_reader").is(":checked") == false){
        const response = await fetch(`/service_orders/${order_id}/create_payment_intent`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ charge: { amount, payment_method, page } })
        });

        const response_data = await response.json();
        var button = document.getElementById('stripe-submit');
        button.disabled = false;
        if (response_data.message){
          document.getElementById('customAlert').style.display = 'block';
          var customAlertText = document.getElementById('customAlertText');
          document.body.style.overflow = 'hidden';
          customAlertText.style.display = 'block';
          document.getElementById('overlay').style.display = 'block';
          button.disabled = true;

          customAlertText.innerText = response_data.message;
          return;
        }
        else{
        document.getElementById('customAlert').style.display = 'none';
        document.body.style.overflow = 'auto';
        document.getElementById('overlay').style.display = 'none';
        button.disabled = false;
        clientSecret = response_data.clientSecret;
        chargeId = response_data.chargeId;
        postalCode = response_data.postalCode;
        loggedInUserId = response_data.user_id
        button.disabled = false;   

        }

        const appearance = {
          theme: 'stripe'
        };

        elements = stripe.elements({ appearance, clientSecret });

        const paymentElement = elements.create("payment");

        paymentElement.mount("#payment-element");

        $("#stripe-submit span").text("Complete purchase");
      }

      checkStatus(chargeId, postalCode);
    }

    async function handleSubmit() {
      stripeSubmitElement.attr('disabled', true);
      
      if ($("#charge_payment_method_stripe_reader").is(":checked") ||
          $("#order_charge_payment_method_stripe_reader").is(":checked")) {
        collectPayment($(".js-stripe-amount").val())
      } else {

        updateCompleted = await updatePaymentIntentData(clientSecret, order_id);
        if (updateCompleted) {
          var cancelCaseExecuted = false;
          try {
            const { error } = await stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: redirectUrl
              },
            });
    
            if (error) {
              if (error.type === "card_error" || error.type === "validation_error") {
                showMessage(error.message);
              } 
              else if (error.type === "invalid_request_error" && error.payment_intent && error.payment_intent.status === "canceled") {
                // disabled complete purchase button
                stripeSubmitElement.attr('disabled', true);
                
                // disabled amount input field
                $(".js-stripe-amount").attr('disabled', true);
                cancelCaseExecuted = true;
                showMessage("Your payment request has been canceled. The page will refresh automatically in 5 seconds to initiate a new payment request for you.");
                
                setTimeout(function() {
                  location.reload();
              }, 5000);
            }
              else
                showMessage("An unexpected error occurred.");
              stopLoader();
            }
          } catch (err) {
            showMessage("An unexpected error occurred.");
          } finally {
            // avoiding complete purchase to be enabled if pages will be reloaded for cancelled payment
            if (!cancelCaseExecuted)
              stripeSubmitElement.attr('disabled', false);
          }
        }
      }
    }


    const showMessage = messageText => {
      const messageContainer = $("#payment-message");

      messageContainer.removeClass("hidden");
      messageContainer.html(messageText);
      stopLoader();

      // clearing the previous timeout for current error message to be visible till duration completes
      clearTimeout(messageTimeout);

      messageTimeout = setTimeout(function () {
        messageContainer.addClass("hidden");
        messageContainer.html('');
      }, 25000);
    }

  }
  else
    change_route();

  if ($stripeErrors.length && $stripeErrors.html().replace('\n', '').trim().length) {
    stopLoader();
  }

  async function checkStatus(chargeId, postalCode) {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      // payment frame disabled to stop user from editing amount
      $("#custom-payment-div").find("*").prop("disabled", true);

      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

      switch (paymentIntent.status) {
        case "succeeded":
          redirectUrl = redirectUrl.split('payment_intent=')[0].slice(0, -1);

          $.ajax({
            type: "PATCH",
            url: "/update_charge_status",
            data: {
              charge_id: chargeId,
              url: redirectUrl,
              postal_code: postalCode,
              page: page
            }
          });

          break;
        case "processing":
          showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          showMessage("Your payment was not successful, please try again.");
          break;
        default:
          showMessage("Something went wrong.");
          break;
      }
  }
  async function change_route(){
    console.log("Inside changing route");
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );    
    if (!clientSecret) {
      return;
    }
    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
    redirectUrl = window.location.href.split('payment_intent=')[0].slice(0, -1);
    switch (paymentIntent.status) {
    case "succeeded":
      $.ajax({
        type: "PATCH",
        url: "/update_charge_status",
        data: {
          payment_intent_id: paymentIntent.id,
          url: redirectUrl,
          postal_code: "charges" in paymentIntent ? paymentIntent.charges.data.last.billing_details.address.postal_code : null,
          page: page,
          payment_status : paymentIntent.status
        }

    });
      break;
    case "processing":
      console.log("Got Processing Status of Intent", paymentIntent.status);
      showMessage("Your payment is processing.");
      break;

    case "requires_payment_method":
      console.log("Got requires_payment_method Status of Intent", paymentIntent.status);
      showMessage("Your payment was not successful, please try again.");
      break;
    default:
      console.log("Default Case of Payment Intent", paymentIntent.status);
      showMessage("Something went wrong.");
      break;
  }
  }
  async function updatePaymentIntentData(clientSecret, orderId) {
    const response = await stripe.retrievePaymentIntent(clientSecret);
      const paymentIntent = response.paymentIntent;
      await new Promise((resolve, reject) => {
        $.ajax({
          type: "PATCH",
          url: "/update_intent",
          data: {
            payment_intent_id: paymentIntent.id,
            service_order_id: orderId,
          },
          success: resolve,
          error: reject,
        });
      });
    return true;
}
};
